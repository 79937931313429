












import { useRouter } from "@/shared/useHelpers";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "UnauthorizedPage",
  setup() {
    const router = useRouter();
    const { url, returnUrl } = router.currentRoute.query;

    return {
      url,
      returnUrl,
    };
  },
});
